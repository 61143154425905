

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import logo from "../../images/cove-logo2.png"

// import { FaHeart, FaFacebookSquare, FaTelegram } from 'react-icons/fa';
// import PaymentIcon from '../GallerySelector/PaymentIcon';
import Footer from './Footer'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

const Layout = ({ children }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar color="white" light expand="md">
        <NavbarBrand href="/" className="cove-brand">
          {/*<img src={logo} alt="tht-logo" className="logo"/>*/}
          Japan Body Check
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/#about">關於JBC</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#membership">會籍內容</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#services">服務內容</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#application">申請方法</NavLink>
            </NavItem>
          </Nav>
          {/*<NavbarText>Simple Text</NavbarText>*/}
        </Collapse>
      </Navbar>
      <div>{children}</div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
