import React, {useState} from "react"
import { FaHeart, FaFacebookSquare, FaTelegram } from 'react-icons/fa';
import PaymentIcon from './PaymentIcon';
import { Link } from "gatsby"
import {
  Container,
  Col,
  Row
} from "reactstrap"

const Footer = () => (
  <footer className="footer footer-black footer-white">
    <Container>
      <Row>
        <Col xs="12" md="4">
          <div className="col-title">
            聯絡我們
          </div>
          <ul>
            <li>
              電話：<br/>
            <a href="tel:852-5571-7750" >+852 5571-7750</a>
            </li>
            <li>
              電郵：<br/>
            <a href="mailto:info.pmsglobal100@gmail.com?Subject=JBC日本體檢查詢" >info.pmsglobal100@gmail.com</a>
            </li>
          </ul>
        </Col>

        <Col xs="12" md="4">
          <div className="col-title">
            條款及細則
          </div>
          <ul>
            <li>
              <Link to="/disclaimer/">免責聲明</Link>
            </li>
            {/*<li>
              <Link to="/giftcard-terms/">禮品卡條款</Link>
            </li>*/}
            <li>
              <Link to="/privacy/">私隱政策</Link>
            </li>
          </ul>
        </Col>

        {/*<Col xs="12" md="3">
          <div className="col-title">
            社交媒體
          </div>
          <ul className="social-icons-ul">
            <li className="social-icons-li">
              <a href="http://blog.creative-tim.com/?ref=pkr-footer">
                <FaFacebookSquare className="social-icon" size="1.6rem"/>
              </a>
            </li>
            <li className="social-icons-li">
              <a href="http://blog.creative-tim.com/?ref=pkr-footer">
                <FaTelegram className="social-icon" size="1.6rem"/>
              </a>
            </li>
          </ul>
        </Col>*/}

        <Col xs="12" md="4">
          <div className="col-title">
            支付方式
          </div>
          <ul className="payment-icons-ul">
            <li className="payment-icons-li">
              <PaymentIcon id="applepay" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="googlepay" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="visa" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="master" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="amex" style={{width: 50, marginBottom: 8}}/>
            </li>

          </ul>


        </Col>
      </Row>

      <Row>
        <div className="credits mx-auto">
          <span className="copyright">
            © {new Date().getFullYear()} Jentic Ltd. All rights reserved.
          </span>
        </div>
      </Row>
    </Container>
  </footer>
)


export default Footer
